<template>
  <div class="container">
    <admin-title></admin-title>
    <div>
      <div class="top">
        <div class="t-content">
          <div class="left">
            <div class="num">
              <span class="title">WinC スカウトチケット数</span>
              <span class="now-num">{{topInfo.residueNum}}&nbsp;&nbsp;</span>
              <span class="total-num">/ {{ topInfo.totalNum }}</span>
            </div>
            <div class="date">
              <span class="title">購入日時</span>
              <span class="grey">{{topInfo.createTimeLabel}}</span>
            </div>
            <div class="date">
              <span class="title">送信期限</span>
              <span>{{topInfo.expirationDateLabel}}</span>
            </div>
          </div>
          <el-tooltip class="item" effect="dark" content="WinC Careerは学生一人ひとりに企業が向き合い、想いを伝えられるよう、一括送信はできない仕様です。" placement="bottom-end">
            <div class="right">
              <img src="../../assets/images/ae/ques_blue.png">
              <span>スカウトの想い</span>
            </div>
          </el-tooltip>

        </div>
      </div>

      <div v-if="true" class="bottom">
        <div v-if="!(type == 4 || type == 5)" class="instrument">スカウト</div>

        <div v-if="type == 1" class="list-table">
          <div class="table-title">保存リスト</div>
          <div class="table-button" @click="changeType(2)">＋　新規作成</div>
          <div  class="table-box">
            <div class="table-content">
              <div class="table-c">
                <el-table
                    :data="tableData"
                    height="19.2rem"
                    style="width: 100%">
                  <el-table-column
                      prop="name"
                      min-width="40%"
                      label="保存リスト名"
                      show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                      prop="userCnt"
                      min-width="20%"
                      label="ユーザー数">
                  </el-table-column>
                  <el-table-column
                      prop="createTime"
                      min-width="40%"
                      show-overflow-tooltip
                      label="作成日">
                  </el-table-column>
                  <el-table-column label="">
                    <template slot-scope="scope">
<!--                      <template v-if="scope.$index == 0">-->
<!--                        -->
<!--                      </template>-->
                      <div class="buttons">
                        <div class="button edit" @click="handleEdit(scope.$index, scope.row)">編集</div>
                        <div class="button delete" @click="handleDelete(scope.$index, scope.row)">削除</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>


        <save-list-add @changeType="changeType" :type="type" :detail="detail" v-if="type == 2 || type == 3"></save-list-add>
        <stu-list @getList="getList" :id="id" @changeType="changeType" v-if="type == 4"></stu-list>
        <stu-detail @changeType="changeType" :id="id" :detailId="detailId" v-if="type == 5"></stu-detail>
        <!--      <scout-detail @changeType="changeType" v-if="type == 2"></scout-detail>-->
      </div>


    </div>
  </div>
</template>

<script>
import saveListAdd from '@/components/pageComponents/saveListAdd.vue'
import stuList from '@/components/pageComponents/stuList.vue'
import stuDetail from '@/components/pageComponents/stuDetail.vue'
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "saveList",

  components: {
    saveListAdd,
    stuList,
    stuDetail
  },

  data() {
    return {
      topInfo: {},

      //
      id: '',
      //学生详情id
      detailId: '',

      type: 1,
      tableData: [],

      detail: {},

      userInfo: {}
    }
  },



  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));

    this.getTopInfo();
    this.getList();
  },

  methods: {
    async getTopInfo() {
      let res = await this.$axios({url: `/compScout/top`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000' && res.data) {
        this.topInfo = res.data;
      }
    },

    async getList() {
      let res = await this.$axios({url: `/compScout/saveList`, data: {compId: this.userInfo.compId}, method: "post"});
      // this.tableData = res.data;
      if(res.code == '000') {
        this.tableData = res.data;
      }
    },

    handleEdit(index, row) {
      this.detail = row;

      this.changeType(3)
      console.log(index, row);
    },

    handleDelete(index, row) {
      let that = this;
      this.$confirm('「' + row.name + '」を削除します。\n' +
          'よろしいですか？', {
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル',
        center: true,
        showClose: false
      }).then(() => {
        that.deleteItem(row.id);
      }).catch(() => {

      });
      console.log(index, row);
    },

    async deleteItem(id) {


      let res = await this.$axios({url: `/compScout/saveList/delete`,
        data: {
          id: id
        }, method: "post"});

      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        this.getList();
      }
    },

    changeType(type, id, detailId) {
      this.type = type;
      if(this.type == 5) {
        document.body.scrollTop=document.documentElement.scrollTop=0;
      }

      if(id) {
        this.id = id;
      }
      if(detailId) {
        this.detailId = detailId;
      }

      console.log(id)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped src="../../style/saveList.css">

</style>
