<template>
  <div class="container">
<!--    <div class="page-title">-->
<!--      <span>スカウト</span>-->
<!--    </div>-->

    <div v-if="type == 2">
      <p class="title">保存リスト ＞ 新規作成</p>
      <div class="form">
        <el-form ref="form" :rules="rules" :model="form" label-width="0px">
        <div class="form-item">
          <div class="form-title">
            <span>保存リスト名</span>
            <img src="../../assets/images/user.png" />
          </div>

            <el-form-item prop="input">
              <el-input show-word-limit :maxlength="20"  v-model="form.input" placeholder=""></el-input>
            </el-form-item>

        </div>
        </el-form>
        <div @click="val(1)" class="form-button">学生一覧 を開く</div>
      </div>
    </div>

    <div v-if="type == 3">
      <p class="title">保存リスト ＞ 編集</p>
      <div class="form">
        <el-form ref="formDetail" :rules="rulesDetail" :model="detail" label-width="0px">

        <div class="form-item">
          <div class="form-title">
            <span>保存リスト名</span>
            <img src="../../assets/images/user.png" />
          </div>
          <el-form-item prop="name">
              <el-input show-word-limit :maxlength="20" v-model="detail.name" placeholder=""></el-input>

          </el-form-item>
        </div>

        </el-form>
        <div class="form-item">
          <div class="form-title">
            <span>ユーザー数</span>
          </div>
          <el-input disabled v-model="detail.userCnt" placeholder=""></el-input>
          <p @click="submit(3)">ユーザーを追加する</p>
        </div>
        <div @click="val(2)" class="form-button">保存する</div>
      </div>
    </div>

  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "saveListAdd",

  data() {
    return {
      form: {},
      userInfo: {},

      rules: {
        input: {required: true, message: '「保存リスト名」が入力されていません', trigger: 'blur'},
      },

      rulesDetail: {
        name: {required: true, message: '「保存リスト名」が入力されていません', trigger: 'blur'},
      },
      // type: 1
    }
  },

  props: {
    type: {
      type: Number
    },

    detail: {
      type: Object
    }
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
  },

  methods: {
    val(type) {
      let that = this;
      if(type == 1) {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            that.submit(type);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else if (type == 2) {
        this.$refs['formDetail'].validate((valid) => {
          if (valid) {
            that.submit(type);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },

    async submit(type) {
      if(type == 1) {
        //新增
        let res = await this.$axios({url: `/compScout/saveList/add`,
          data: {
            compId: this.userInfo.compId,
            operatorId: this.userInfo.id,
            name: this.form.input
          }, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });

          this.$emit('changeType', 4, res.data)
        }

      } else if(type == 2) {
        //修改
        let res = await this.$axios({url: `/compScout/saveList/update`,
          data: {
            compId: this.userInfo.compId,
            operatorId: this.userInfo.id,
            name: this.detail.name,
            id: this.detail.id
          }, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
        }

        this.$emit('changeType', 1)
      } else {
        this.$emit('changeType', 4, this.detail.id)
      }
    }
  }
}
</script>

<style scoped>
.bottom {
  padding-bottom: 0 !important;
}

.container {
  /*padding: 0 3.125rem 3.125rem;*/
}

.container .page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.390625px solid #A2A2A2;
  padding-bottom: 0.733rem;
}

.container .page-title>span {

  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  /*line-height: 13px;*/

  color: #424242;
}

.title {

  font-style: normal;
  font-weight: 600;
  font-size: .9375rem;
  line-height: 1.1rem;

  color: #424242;
  /*margin-top: 3.125rem;*/
  margin-bottom: 1.4rem;
}

.form {
  flex: 1;
  min-height: 100px;
  padding-bottom: 1.875rem;
  background: #FFFFFF;
  border-top: 1px transparent solid;
  box-shadow: .234375rem .234375rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .625rem;
}

.form .form-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.875rem;
}

.form-title {
  display: flex;
  align-items: center;
  /*line-height: 3.634rem;*/
  width: 9rem;
  position: relative;

  font-style: normal;
  font-weight: 600;
  font-size: 1.09375rem;
  /*line-height: 13px;*/
  justify-content: center;
  color: #424242;
  margin-right: 1.267rem;
  /*margin-right: 1rem;*/
}

.form-item p {
  position: absolute;

  font-style: normal;
  font-weight: 600;
  font-size: .9375rem;
  /*line-height: 11px;*/
  text-align: right;
  text-decoration-line: underline;
  right: calc(50% - 30.1646rem);
  color: #2876F6;
  cursor: pointer;
  z-index: 99;
}

.form-title img {
  width: 1.077rem;
  margin-left: .203rem;
}


.form .form-item /deep/ .el-input__inner {

  background: transparent;
  /*background: #FFFFFF;*/
  /*border: 0.078125rem solid #A3A3A3;*/
  /*border-radius: .390625rem;*/
  border: 0.0585938rem solid #CCCCCC;
  width: 39.062rem;
  height: 2.734rem;

  box-shadow: inset 0.078125rem 0.078125rem .390625rem rgba(0, 0, 0, 0.23);


  font-style: normal;
  font-weight: 400;
  font-size: 1.09375rem;
  line-height: 13px;

  color: #000000;
  padding-left: 15px;
  /*padding-right: 0;*/
  /*margin-right: 30px;*/

  position: relative;
  z-index: 10;
  /*cursor: pointer;*/
}

.form .form-item /deep/ .el-input {
  width: auto;
}

.form .form-item /deep/ .el-select .el-input .el-select__caret {
  line-height: 2.734rem;
}

.form .form-item /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: auto;
  /*position: relative;*/
}

.form .form-item.date-picker /deep/ .el-input__icon {
  line-height: 2.734rem;
}

.form .form-item.date-picker /deep/ .el-input__icon:before{
  content: "\e78e";
}

.form .form-item.date-picker /deep/ .el-input__prefix {
  right: 5px;
  display: inline-block;
  text-align: right;
}

.form .form-item.date-picker /deep/ .el-input__suffix {
  display: none;
}

.form .form-button {
  width: 15.375rem;
  height: 2.5rem;
  background: #FFFFFF;
  border: 0.078125rem solid #CCCCCC;
  border-radius: 1.95312rem;


  font-style: normal;
  font-weight: 700;
  font-size: 1.09375rem;
  line-height: 2.5rem;
  text-align: center;
  color: #CCCCCC;
  transition: .3s;
  margin: 1.875rem auto 0;
  cursor: pointer;
}

.form .form-button:hover {
  background: #15B1CE;
  color: white;
}

.el-form-item {
  margin-bottom: 0;
}
</style>
