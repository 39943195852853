<template>
  <div>
    <div class="cha" style="background: #fff;" v-if="isCha">

      <span>スカウト残数</span>
      <p>{{topInfo.residueNum - userCount}}<i>通</i></p>
      <p>
        /{{topInfo.totalNum}}
      </p>
      <img @click="isCha = false" src="../../assets/images/cha.png" />
    </div>

    <div class="container" style="display: block;" >
      <div class="page-title">
        <span>スカウト</span>
        <div @click="nav(1)" class="export">
          <div class="export-title">
            <span>選択を完了する</span>
          </div>
        </div>
      </div>
      <div class="pagination">
        <p>保存リスト ＞ 新規作成 ＞ 学生一覧</p>
        <el-pagination
            :page-size="pageSize"
            :pagerCount="5"
            layout="pager"
            :current-page="search.pageIndex"
            @current-change="handleCurrentChange"
            :total="pageCount">
        </el-pagination>
      </div>

      <div class="note-list">
        <div class="note-left">
          <div>
            <p class="left-title">検索条件設定</p>
            <div class="input-item">
              <div class="input-title">
                <span>卒業年度</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="卒業年度で検索が可能です。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.graduationYear" placeholder="">
                <el-option v-for="(item, index) in searchItem.graduationYear" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>


            </div>

            <div class="input-item">
              <div class="input-title">
                <span>学校区分</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="現在在学中の学校区分で検索ができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.schoolDivision" placeholder="">
                <el-option v-for="(item, index) in searchItem.schoolDivision" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

<!--            <div class="input-item interview">-->
<!--              <div class="input-title">-->
<!--                <span>ステータス</span>-->
<!--                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="学生のステータスで検索ができます。フロー運用で新たに作成したフローは設定できません。" placement="right-end">-->
<!--                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>-->
<!--                </el-tooltip>-->
<!--              </div>-->
<!--              <el-select multiple class="select" v-model="search.detailContent" placeholder="">-->
<!--                <el-option v-for="(item, index) in searchItem.detailContent" :key="index" :label="item.kbName" :value="item.kbCode"></el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--            &lt;!&ndash;          <el-radio class="select-progress" v-model="radio" label="1">内定者を除く</el-radio>&ndash;&gt;-->
<!--            <div class="input-item date-range">-->
<!--              <div class="input-title">-->
<!--                <span>エントリー期間</span>-->
<!--                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="エントリー期間で検索が可能です。上段は「どの日付から検索するか」、下段は「どの日付まで検索するか」、日付を設定します（上段/下段のいずれかのみの設定も可能です）。" placement="right-end">-->
<!--                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>-->
<!--                </el-tooltip>-->
<!--              </div>-->
<!--              <el-date-picker-->
<!--                  v-model="search.searchRange"-->
<!--                  type="daterange"-->
<!--                  format="yyyy年/MM月/dd日"-->
<!--                  :clearable="true"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  start-placeholder="年/月/日"-->
<!--                  end-placeholder="年/月/日">-->
<!--              </el-date-picker>-->
<!--            </div>-->
            <el-checkbox-group v-model="search.group">
              <div class="groups">
                <el-checkbox label="favoriteUserFlg" class="select-progress checkbox">保存済みユーザーを表示</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="貴社をお気に入りに保存している学生のみを表示できます" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="groups">
                <el-checkbox label="exceptSentFlg" class="select-progress checkbox">過去スカウト送信者を除く</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="過去に貴社がスカウトを送ったことがある学生を除外することができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="groups">
                <el-checkbox label="exceptInternalFlg" class="select-progress checkbox">内定者を除く</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="1社でもステータスが「内定」となっている学生を除外することができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>

            </el-checkbox-group>
            <div class="input-item">
              <div class="input-title">
                <span>希望年収</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望年収で検索が可能です。下限額と上限額の設定ができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="money-ranger">
                <el-input type="number" v-model="search.expectedAnnualSalaryMin" placeholder="200"></el-input>
                <p>〜</p>
                <el-input type="number" v-model="search.expectedAnnualSalaryMax" placeholder="350"></el-input>
              </div>
            </div>
            <!--          <div class="input-item select-progress">-->
            <!--            <div class="input-title">-->
            <!--              <span>タスク未対応</span>-->
            <!--              <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="フロー設計のデフォルト項目。『説明会エントリー / エントリー / 面接 / 内定』 からドロップダウンで1つ選択が可能。" placement="right-end">-->
            <!--                <span><img src="../../assets/images/entryLevel/ques.png" /></span>-->
            <!--              </el-tooltip>-->
            <!--            </div>-->
            <!--            <el-select class="select" v-model="value" placeholder="">-->
            <!--              <el-option label="社内選考中" value="社内選考中"></el-option>-->
            <!--            </el-select>-->
            <!--          </div>-->




            <div class="input-item">
              <div class="input-title">
                <span>希望業種</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望業種で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple  class="select" v-model="search.expectedIndustry" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedIndustry" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item">
              <div class="input-title">
                <span>希望職種</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望職種で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.expectedOccupation" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedOccupation" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item">
              <div class="input-title">
                <span>希望勤務地</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望勤務地で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.expectedWorkplace" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedWorkplace" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>一緒に働きたい社長像</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="一緒に働きたい社長像のテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.mostImportant" placeholder="">
                <el-option v-for="(item, index) in searchItem.mostImportant" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>就職活動で企業選びの軸にしていること</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="就職活動で企業選びの軸にしていることのテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.importanceOfComp" placeholder="">
                <el-option v-for="(item, index) in searchItem.importanceOfComp" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>学生時代に力を入れたこと</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="学生時代に力を入れたことのテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select multiple class="select" v-model="search.studentEffort" placeholder="">
                <el-option v-for="(item, index) in searchItem.studentEffort" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item input-bottom">
              <div class="input-title">
                <span>キーワード検索</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="フリーワード検索が可能です。フリーワードは1つだけ入力してください。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-input v-model="search.keyword" placeholder="学部、学科、コース/専攻、免許/資格 など"></el-input>
            </div>

            <div class="left-bottom">
              <div @click="searchList" class="search">検索する</div>
              <a style="cursor: pointer" @click="clear()">検索条件をクリア</a>
            </div>
          </div>
        </div>

        <div class="note-right">
          <div class="page-result">
            <div class="search-result">
              <span class="search-title">検索結果</span>
              <span class="search-num">{{pageCount}}</span>
            </div>
            <div class="page-size">
              <p>表示件数　<i @click="changePageSize(30)" :class="search.pageSize == 30 ? 'active' : ''">30件</i>｜
                <i @click="changePageSize(50)" :class="search.pageSize == 50 ? 'active' : ''">50件</i>｜
                <i @click="changePageSize(               100)" :class="search.pageSize == 100 ? 'active' : ''">100件</i></p>
            </div>
          </div>
          <div class="stu-list" v-for="(item, index) in list" :key="index">
            <div class="stu-left">
              <div class="status-grey status" v-if="item.saveFlg == 0"></div>
              <div class="status-yellow status" v-else>保存リスト追加済</div>

              <div style="cursor: pointer" class="head-img"  @click="nav(5, item.id)">
                <img v-if="item.genderName == '男性'" src="../../assets/images/stu_header.png" />
                <img v-if="item.genderName == '女性'" src="../../assets/images/w_stu_header.png" />
                <img v-if="item.genderName == '無回答'" src="../../assets/images/wz_stu_header.png" />
              </div>
              <div>
                <div class="stu-evt-pc">
                  ■最終ログイン<br/>
                  {{item.lastLoginDate}}<br/>
                  ■ユーザー情報最終変更<br/>
                  {{item.lastUpdateDate}}<br/>
                </div>
              </div>
              <div class="hope-item">
                <p>＜ 希望業種 ＞</p>
                <span>{{item.expectedIndustry}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望職種 ＞</p>
                <span>{{item.expectedOccupation}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望勤務地 ＞</p>
                <span>{{item.expectedWorkplace}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望年収 ＞</p>
                <span>{{item.expectedAnnualSalaryMin}}〜{{item.expectedAnnualSalaryMax}}万円</span>
              </div>
            </div>
            <div class="stu-right">
              <div class="right-item">
                <div class="item-left">
                  <span>卒業年度</span>
                </div>
                <div class="item-right">
                  <span>{{item.graduationYearName.substring(0, 4)}}年</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学校区分</span>
                </div>
                <div class="item-right">
                  <span>{{item.schoolDivisionName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>居住地</span>
                </div>
                <div class="item-right">
                  <span>{{item.prefectureName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学部・学科</span>
                </div>
                <div class="item-right">
                  <span>{{item.faculty}} {{item.facultyDepartment}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>免許・資格</span>
                </div>
                <div class="item-right">
                  <span>{{item.qfName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>一緒に働きたい社長像</span>
                </div>
                <div class="item-right">
                  <img src="../../assets/images/ma.png"/>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>就職活動で企業選びの軸にしていること</span>
                </div>
                <div class="item-right">
                  <img src="../../assets/images/ma.png"/>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学生時代に<br/>力を入れたこと</span>
                </div>
                <div class="item-right">
                  <img src="../../assets/images/ma.png"/>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "savaListStuList",

  data() {
    return {
      exportActive: false,
      userCount: 0,
      isCha: true,

      value: '',
      topInfo: {},
      radio: [],

      value1: '',

      detailActive: false,
      list: [],
      pageSize: 30,
      pageCount: 0,
      pageIndex: 1,
      search: {
        graduationYear: [],
        schoolDivision: [],
        detailContent: [],
        expectedIndustry: [],
        expectedOccupation: [],
        expectedWorkplace: [],
        mostImportant: [],
        importanceOfComp: [],
        studentEffort: [],
        searchRange: [],
        group: [],
        pageSize: 30,
        pageCount: 0,
        pageIndex: 1,
      },
      searchItem: {},

      masterName: [
        {name: '卒業年度', value: 'graduationYear'},
        {name: '学校区分', value: 'schoolDivision'},
        {name: '業界', value: 'expectedIndustry'},
        {name: '職種', value: 'expectedOccupation'},
        {name: '都道府県', value: 'expectedWorkplace'},
        {name: '社長特徴', value: 'mostImportant'},
        {name: '就職活動で企業選びの軸にしていること', value: 'importanceOfComp'},
        {name: '学生時代に力を入れたこと', value: 'studentEffort'},
      ],
    }
  },

  props: {
    id: {
      type: Number
    },
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    this.init();
    this.getTopInfo();
  },

  methods: {
    async getTopInfo() {
      let res = await this.$axios({url: `/compScout/top`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000' && res.data) {
        this.topInfo = res.data;
      }
    },

    clear() {
      this.search = {
        graduationYear: [],
        schoolDivision: [],
        detailContent: [],
        expectedIndustry: [],
        expectedOccupation: [],
        expectedWorkplace: [],
        mostImportant: [],
        importanceOfComp: [],
        studentEffort: [],
        searchRange: [],
        group: [],
        pageSize: 30,
        pageCount: 0,
        pageIndex: 1,
      };
      this.searchList();
    },

    async init() {
      this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));

      await this.initSearch();
      let params = localStorage.getItem('params');
      if(params) {
        this.search =  JSON.parse(decodeURI(params));
        localStorage.removeItem('params');
      }
      await this.searchList();
    },

    async initSearch() {
      let res = null;

      for (let i = 0; i < this.masterName.length; i++) {
        this.initList(i);
      }
      res = await this.$axios({url: `/common/flowStatusSel`, data: {}, method: "post"});
      this.searchItem['detailContent'] = res.data;
      this.search.detailContent=[this.$route.query.detailContent];


      res = await this.$axios({url: `/compUser/selUsers`, data: {}, method: "post"});
      this.selUsers = res.data;

      // console.log(this.searchItem)

      this.$forceUpdate();
    },

    async initList(i) {
      let res = await this.$axios({url: `/common/getMasterListByName/${this.masterName[i].name}`, data: {}, method: "get"});
      this.searchItem[this.masterName[i].value] = res.data;
    },

    changeExportActive() {
      // console.log("sss")
      this.exportActive = !this.exportActive;
    },


    async searchList() {
      let data = {
        graduationYear: this.search.graduationYear.join(),
        detailContent: this.search.detailContent.join(),
        expectedIndustry: this.search.expectedIndustry.join(),
        expectedOccupation: this.search.expectedOccupation.join(),
        expectedWorkplace: this.search.expectedWorkplace.join(),
        importanceOfComp: this.search.importanceOfComp.join(),
        mostImportant: this.search.mostImportant.join(),
        schoolDivision: this.search.schoolDivision.join(),
        searchRange: this.search.searchRange.join(),
        studentEffort:  this.search.studentEffort.join(),
        compId: this.userInfo.compId,
        pageIndex: this.search.pageIndex,
        pageSize: this.search.pageSize,
        operatorId: this.userInfo.id,
        favoriteUserFlg: 0,
        exceptSentFlg: 0,
        exceptInternalFlg: 0,
        keyword: this.search.keyword,
        saveListId: this.id,
        expectedAnnualSalaryMin: this.search.expectedAnnualSalaryMin,
        expectedAnnualSalaryMax: this.search.expectedAnnualSalaryMax
      };

      // this.search.studentOnlyFlg = 0;
      // this.search.exceptInternalFlg = 0;
      // this.search.exceptDismissFlg = 0;
      let that = this;
      this.search.group.forEach((item) => {
        data[item] = 1;
      })

      if(this.search.searchRange.length > 0) {
        data.entryDateFrom = this.search.searchRange[0];
        data.entryDateTo = this.search.searchRange[1];
      }


      // this.search.saveFig = 0;
      // if(!(this.search.saveFig)) {
        // this.search.saveFig = 0;
      // }
      this.search.compId = this.userInfo.compId;
      // this.search.pageIndex = this.pageIndex;
      // this.search.pageSize = this.pageSize;
      this.search.operatorId = this.userInfo.id;


      let res = await this.$axios({url: `/compScout/userList`, data: data, method: "post"});
      if(res.code == '000') {
        res.data.items.forEach(item => {
          // console.log(item.compUserIds)
          // if(item.compUserIds) {
          //   // console.log("ssss")
          //   item.compUserIds = item.compUserIds.split(',');
          // } else {
          //   item.compUserIds = [];
          // }

          // item.compUserIds = item.compUserIds.split(',');
        })


        this.list = res.data.items;
        this.pageCount = res.data.totalCount;
        this.userCount = res.data.userCount;
        console.log(this.list)
        this.$forceUpdate();
      }
      // console.log(this.search)

    },
    handleCurrentChange(val) {
      // console.log(val, this.pageIndex)
      this.search.pageIndex = val;
      this.searchList();
    },

    cancelActive() {
      // console.log("sss1")
      this.exportActive = false;
    },

    changePageSize(page) {
      this.search.pageSize = page;
      this.searchList();
    },

    nav(type, id) {
      this.$emit('changeType', type, this.id, id)

      if(type == 1) {
        this.$emit('getList')
      } else if(type == 5) {
        localStorage.setItem('params', encodeURI(JSON.stringify(this.search)));
      }
    }
  }
}
</script>

<style scoped src="../../style/stuList.css">

</style>
