<template>
  <div>
    <div class="container" style="display: block;" >
      <div class="page-title">
        <span>スカウト</span>
        <div class="export">

          <div @click="save(1)" class="export-title" v-if="detail.saveFlg == 1" style="background: #BFC5C6;">
            <span>保存リストから外す</span>
          </div>
          <div @click="save(2)" class="export-title" v-else>
            <span>＋　保存リスト追加</span>
          </div>
        </div>
      </div>
      <div class="pagination">
        <p>保存リスト ＞ 新規作成 ＞ 学生一覧 ＞ 学生詳細</p>
      </div>

      <div class="note-list">
        <div class="note-right">
          <div class="stu-list">
            <div class="stu-left">
              <div class="status-grey status" v-if="detail.saveFlg == 0"></div>
              <div class="status-yellow status" v-else>保存リスト追加済</div>
<!--              <div class="status-grey status"></div>-->
              <!--              <div class="status-yellow status"></div>-->

              <div class="head-img">
                <img v-if="detail.genderName == '男性'" src="../../assets/images/stu_header.png" />
                <img v-if="detail.genderName == '女性'" src="../../assets/images/w_stu_header.png" />
                <img v-if="detail.genderName == '無回答'" src="../../assets/images/wz_stu_header.png" />
              </div>
              <div>
                <div class="stu-evt-pc">
                  ■最終ログイン<br/>
                  {{detail.lastLoginDate}}<br/>
                  ■ユーザー情報最終変更<br/>
                  {{detail.lastUpdateDate}}<br/>
                </div>
              </div>
              <div class="hope-item">
                <p>＜ 希望業種 ＞</p>
                <span>{{detail.expectedIndustry}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望職種 ＞</p>
                <span>{{detail.expectedOccupation}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望勤務地 ＞</p>
                <span>{{detail.expectedWorkplace}}</span>
              </div>
              <div class="hope-item">
                <p>＜ 希望年収 ＞</p>
                <span>{{detail.expectedAnnualSalaryMin}}〜{{detail.expectedAnnualSalaryMax}}万円</span>
              </div>
            </div>
            <div class="stu-right">
              <div class="right-item">
                <div class="item-left">
                  <span>卒業年度</span>
                </div>
                <div class="item-right">
                  <span v-if="detail.graduationYearName">{{detail.graduationYearName.substring(0, 4)}}年</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学校区分</span>
                </div>
                <div class="item-right">
                  <span>{{detail.schoolDivisionName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>居住地</span>
                </div>
                <div class="item-right">
                  <span>{{detail.prefectureName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学部・学科</span>
                </div>
                <div class="item-right">
                  <span>{{detail.faculty}} {{detail.facultyDepartment}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>免許・資格</span>
                </div>
                <div class="item-right">
                  <span>{{detail.qfName}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>一緒に働きたい社長像</span>
                  <img src="../../assets/images/eye.png"/>
                </div>
                <div class="item-right">
                  <p>{{detail.mostImportantName}}</p>
                  <span>{{detail.idealPresidentImage}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>就職活動で企業選びの<br/>軸にしていること</span>
                  <img src="../../assets/images/entryLevel/heart.png"/>
                </div>
                <div class="item-right">
                  <p>{{detail.importanceOfCompName}}</p>
                  <span>{{detail.importanceOfCompReason}}</span>
                </div>
              </div>
              <div class="right-item">
                <div class="item-left">
                  <span>学生時代に<br/>力を入れたこと</span>
                  <img src="../../assets/images/diamond.png"/>
                </div>
                <div class="item-right">
                  <p>{{detail.studentEffortName}}</p>
                  <span>{{detail.studentEffortReason}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="nav()" class="return green-btn">学生一覧へ戻る</div>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "stuDetail",
  props: {
    id: {
      type: Number
    },

    detailId: {
      type: Number
    },
  },

  data() {
    return {
      detail: {},

      userInfo: {}
    }
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    this.getDetailData();

  },

  methods: {

    async getDetailData() {
      let res = await this.$axios({url: `/compScout/userDetail`, data: {userId: this.detailId, saveListId: this.id}, method: "post"});
      if(res.code == '000') {
        this.detail = res.data;
      }
    },

    async save(type) {
      if(type == 1) {
        //取消保存
        let res = await this.$axios({url: `/compScout/saveListDetail/delete`, data: {
            operatorId: this.userInfo.id,
            userId: this.detailId,
            id: this.id}, method: "post"});
        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.detail.saveFlg = 0;
        }
      } else {
        //保存
        let res = await this.$axios({url: `/compScout/saveListDetail/add`, data: {
            operatorId: this.userInfo.id,
            userId: this.detailId,
            id: this.id}, method: "post"});
        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.detail.saveFlg = 1;
        }
      }
    },

    nav() {
      this.$emit('changeType', 4)
    }
  }
}
</script>

<style scoped src="../../style/stuDetail.css">

</style>
